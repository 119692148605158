.wrapper {
  z-index: 910;
  padding: 16px;
  margin-top: 32px;
  overflow: hidden;

  margin-right: 32px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: var(--color-white);
  height: 400px;
  width: 200px;

  border-radius: 18px;
  &:focus {
    outline: none;
  }
}

.all-countries {
  margin-top: 48px;
  height: 320px;
  position: relative;
  overflow: auto;
}

.all-countries::-webkit-scrollbar {
  width: 0.5em;
}

.wrapper:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
  width: 200px;
  height: 4em;
  border-radius: 18px;
}

.trigger {
  &:focus {
    outline: none;
  }
}

.search {
  --transition: 200ms;
  --input-font-size: var(--font-size-xs);
  --input-padding-v: 17px;
  --input-padding-h: 11px;
  --input-br: 14px;
  @media (--large) {
    --input-font-size: var(--font-size-2xs);
    --input-padding-v: 10px;
    --input-padding-h: 0.5em;
  }

  padding-inline: var(--input-padding-v);
  padding-block: var(--input-padding-h);
  font-size: var(--input-font-size);
  background: var(--color-primary-20);
  border-radius: var(--input-br);
  border: 0;
  left: 10px;

  &:focus {
    border: 1px solid var(--color-primary);
  }
  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: var(--color-primary-70);
    font-size: var(--input-font-size);
  }

  &:hover {
    &::placeholder {
      opacity: 0.6;
    }
  }
}

.selected {
  text-decoration: underline;
  border-radius: 18px;
  text-underline-offset: 4px;
}

.country {
  text-align: left;
  font-family: var(--font-haas-grot-display);
  font-size: var(--font-size-menu-item);

  cursor: pointer;
  &:hover {
    color: var(--color-primary-60);
  }
  &:focus {
    outline: none;
    color: var(--color-primary-60);
  }
}

.group-label {
  font-family: var(--font-haas-grot-display);
  font-size: var(--font-size-menu-item);
  font-weight: 600;
  color: var(--color-primary-60);
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
}

.search {
  display: flex;
  flex-direction: column;
  z-index: 910;
  &:focus {
    outline: none;
  }
  position: fixed;
}

.countries {
  display: flex;
  flex-direction: column;
  gap: 12px;
  line-heigh: 1.5;
  &:focus {
    outline: none;
  }
  margin-bottom: 16px;
}

.scroll-down {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:focus {
    outline: none;
  }

  transform: rotate(180deg);

  & :hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    cursor: wait;
  }
}

.scroll-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:focus {
    outline: none;
  }

  & :hover {
    background-color: var(--color-primary);
    color: var(--color-white);
    cursor: wait;
  }
}
